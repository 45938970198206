@import '../../styles/vars';

.notFound {

    .section_inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex: 1 1 auto;

        .notFound_icon {
            position: relative;
            z-index: 1;
            max-width: 500px;

            @media (max-width: $tablet) {
                max-width: 540px;
            }

            @media (max-width: $mobile) {
                max-width: 320px;
            }

            &::before {
                position: absolute;
                content: '';
                width: 100%;
                height: 100%;
                border-radius: 50%;
                background: $color_orange;
                filter: blur(145px);
                z-index: -1;
            }

            svg {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .notFound_box {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: relative;
            max-width: 340px;
            width: 100%;
            z-index: 1;
            margin-top: 56px;

            @media (max-width: $tablet) {
                margin-top: 35px;
            }

            .notFound__title {
                margin-bottom: 26px;
                text-align: center;
            }
        }
    }
}