@import '../../styles/vars';

.header {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 9;

    .header_inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 0;

        @media (max-width: $tablet) {
            padding: 16px 0;
        }

        .logo {
            max-width: 80px;

            @media (max-width: $tablet) {
                max-width: 50px;
            }

            @media (max-width: $mobile) {
                max-width: 40px;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .burger {
            max-width: 58px;
            cursor: pointer;

            @media (max-width: $tablet) {
                max-width: 36px;
            }

            @media (max-width: $mobile) {
                max-width: 32px;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}