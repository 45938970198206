$color_white: #FFFFFF; 
$color_black: #000000;
$color_bordo: #811C3C;
$color_l_bordo: #AE3532;
$color_d_bordo: #610B2F;
$color_orange: #E05327;
$color_l_orange: #F47A4C;
$color_gray: #5B5B5B;

$primary_font: 'ABC Favorit Pro Book', sans-serif;
$secondary_font: 'Grato Grotesk DEMO', sans-serif;

$desk_min: 1440px;
$tablet: 1024px;
$mobile: 495px;