@import '../../styles/vars';

.aside {
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: flex-end;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;
    z-index: 10;

    &.__active {
        opacity: 1;
        pointer-events: all;
    }

    .aside__shadow {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(24, 24, 24, 0.79);
    }

    .aside_inner {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        position: relative;
        background-color: $color_d_bordo;
        max-width: 700px;
        width: 100%;
        padding: 20px 64px;
        z-index: 1;

        @media (max-width: $tablet) {
            max-width: 100%;
            padding: 16px 32px;
        }

        @media (max-width: $mobile) {
            padding: 16px;
        }

        .aside__head {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            @media (max-width: $tablet) {
                justify-content: space-between;
            }
            
            .logo {
                display: none;
                max-width: 50px;

                @media (max-width: $tablet) {
                    display: block;
                }

                @media (max-width: $mobile) {
                    max-width: 40px;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .close {
                cursor: pointer;
                max-width: 58px;

                @media (max-width: $tablet) {
                    max-width: 36px;
                }

                @media (max-width: $mobile) {
                    max-width: 32px;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }


        .nav {
            width: 100%;
            padding-top: 65px;

            .nav__list {

                .nav__list_item {
                    position: relative;
                    display: flex;
                    flex-wrap: nowrap;
                    align-items: center;
                    justify-content: space-between;
                    cursor: pointer;
                    padding-bottom: 14px;

                    @media (max-width: $tablet) {
                        padding-bottom: 10px;
                    }

                    @media (max-width: $mobile) {
                        padding-bottom: 8px;
                    }

                    &:last-child {

                        &::before {
                            display: none;
                        }
                    }

                    &:hover {

                        svg {
                            transform: translateX(10px);
                        }
                    }

                    &::before {
                        position: absolute;
                        content: '';
                        height: 1px;
                        bottom: 0;
                        transition: 0.5s;
                    }

                    &::before {
                        left: 0;
                        width: 100%;
                        background-color: $color_white;
                        z-index: 1;
                    }

                    &:not(:last-child) {
                        margin-bottom: 14px;

                        @media (max-width: $tablet) {
                            margin-bottom: 10px;
                        }

                        @media (max-width: $mobile) {
                            margin-bottom: 8px;
                        }
                    }

                    a, p {
                        font-weight: 400;
                    }

                    svg {
                        min-width: 32px;
                        max-width: 32px;
                        height: 32px;
                        pointer-events: none;
                        transition: 0.5s;
                        margin-left: 24px;

                        @media (max-width: $tablet) {
                            min-width: 28px;
                            max-width: 28px;
                            height: 28px;
                        }
                    }
                }
            }
        }
    }
}