@import '../../styles/vars';

.answer {
    position: relative;
    z-index: 1;

    &::before,
    &::after {
        position: absolute;
        content: '';
        border-radius: 50%;
        filter: blur(210px);
        background: radial-gradient(26.33% 26.33% at 50% 50%, #FEB99F 0.01%, #FFF 100%);
        pointer-events: none;
        z-index: -1;
    }

    &::before {
        width: 580px;
        height: 580px;
        top: -135px;
        left: 140px;

        @media (max-width: $mobile) {
            width: 360px;
            height: 360px;
            top: -100px;
            left: -230px;
        }
    }

    &::after {
        width: 470px;
        height: 470px;
        top: 250px;
        right: -350px;

        @media (max-width: $mobile) {
            width: 360px;
            height: 360px;
            right: -240px;
        }
    }

    .answer__bg {
        position: absolute;
        top: 55%;
        right: -90px;
        pointer-events: none;
        transform: translateY(-50%);

        @media (max-width: $desk_min) {
            right: -150px;
        }

        @media (max-width: $tablet) {
            top: 30%;
            right: -50px;
            width: 860px;
        }

        @media (max-width: $mobile) {
            top: 35%;
            right: -120px;
            width: 660px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .section_inner {
        display: flex;
        align-items: center;
        flex: 1 1 auto;
        padding-left: 80px;

        @media (max-width: $tablet) {
            padding-left: 30px;
            padding-right: 30px;
            padding-bottom: 75px;
            justify-content: center;
            align-items: flex-end;
        }

        @media (max-width: $mobile) {
            padding-left: 0;
            padding-right: 0;
        }

        .answer__box {
            position: relative;
            max-width: 640px;
            width: 100%;
            background-color: $color_white;
            padding: 46px;
            border-radius: 12px;
            z-index: 1;

            @media (max-width: $mobile) {
                padding: 32px;
            }

            .answer__box_suptitle {
                color: $color_black;
                margin-bottom: 26px;
            }

            .answer__box_title {
                font-family: $secondary_font;
                color: $color_bordo;
            }

            .answer__box_text {
                color: $color_black;
                margin-top: 26px;
                padding-right: 90px;

                @media (max-width: $mobile) {
                    margin-top: 18px;
                    padding-right: 30px; 
                }
            }

            .answer__box_descr {
                margin-top: 22px;
                color: $color_gray;
                opacity: 0.5;

                @media (max-widht: $mobile) {
                    margin-top: 8px;
                }
            }

            .answer__box_actions {
                display: flex;
                margin-top: 72px;

                @media (max-width: $mobile) {
                    margin-top: 36px; 
                }

                &.__fill {

                    .answer__box_btn {
                        max-width: 100%;
                    }
                }

                .answer__box_btn {
                    max-width: 380px;
                    width: 100%;
                }

                .btn.__copy {
                    margin-left: 22px;
                    margin-right: 10px;

                    @media (max-width: $mobile) {
                        margin-left: 16px;
                        margin-right: 6px;
                    }
                }
            }
        }
    }
}