@import '../../styles/vars';

.questions {
    position: relative;
    z-index: 1;
    padding-bottom: 75px;

    &::before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        background-color: rgba(#F7A585, 1);
        animation: hideBg 1s ease-in-out forwards 1s;
        z-index: 10;
        pointer-events: none;
    }

    @keyframes hideBg {
        0% {
            background-color: rgba(#F7A585, 1);
        }

        100% {
            background-color: rgba(#F7A585, 0);
        }
    }

    .section_inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 280px;

        @media (max-width: $tablet) {
            padding-top: 420px;
        }

        @media (max-width: $mobile) {
            padding-top: 250px;
        }

        .questions__bg {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            pointer-events: none;
            z-index: -1;

            @media (max-width: $tablet) {
                display: none;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .questions__icon {
            width: 1100px;
            display: none;
            position: absolute;
            top: -225px;
            z-index: -1;

            @media (max-width: $tablet) {
                display: block;
            }

            @media (max-width: $mobile) {
                width: 580px;
                top: -110px;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .questions__title {
            font-size: 28px;
            font-weight: 700;
            line-height: 122%;
            text-transform: uppercase;
            max-width: 630px;
            margin: 0 auto;
            letter-spacing: 0;
            color: $color_bordo;
            text-align: center;
            font-family: $secondary_font;

            @media (max-width: $tablet) {
                max-width: 530px;
            }

            @media (max-width: $mobile) {
                font-size: 18px;
            }
        }

        .questions__items {
            display: flex;
            flex-direction: column;
            align-items: center;
            max-width: 600px;
            width: 100%;
            margin-top: 20px;

            @media (max-width: $tablet) {
                margin-top: 28px;
            }

            .questions__item {
                position: relative;
                width: auto;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 14px 20px;
                font-size: 28px;
                font-weight: 400;
                line-height: 120%;
                border-radius: 60px;
                cursor: pointer;
                height: auto;
                background-color: $color_l_orange;
                transition: 0.5s;

                @media (max-width: $mobile) {
                    padding: 12px;
                }

                &:hover {
                    padding-right: 90px;

                    @media (max-width: $mobile) {
                        padding-right: 48px;
                    }

                    .questions__item_icon {
                        margin-left: 36px;
                        opacity: 1;
                    }
                }

                &:not(:last-child) {
                    margin-bottom: 6px;

                    @media (max-width: $tablet) {
                        margin-bottom: 4px;
                    }
                }

                &:nth-child(1) {
                    background-color: #811C3C;
                }

                &:nth-child(2) {
                    background-color: #AE3532;
                }

                &:nth-child(3) {
                    background-color: #CF482B;
                }

                &:nth-child(4) {
                    background-color: #ED6034;
                }

                &:nth-child(5) {
                    background-color: #FF7A48;
                }

                &:nth-child(6) {
                    background-color: #FD9973;
                }

                &:nth-child(7) {
                    background-color: #F9B095;
                }

                .questions__item_text {

                    @media (max-width: $mobile) {
                        font-size: 16px;
                    }
                }

                .questions__item_icon {
                    position: absolute;
                    right: 20px;
                    opacity: 0;
                    transition: 0.5s;
                    min-width: 34px;
                    max-width: 34px;

                    @media (max-width: $mobile) {
                        right: 12px;
                        min-width: 24px;
                        max-width: 24px;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }
    }
}