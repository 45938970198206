@import '../../styles/vars';

.promo {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 100px;

    &.__hidden {

        &::before {
            opacity: 1;
        }
    }

    &::before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        background: #F7A585;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 11;
        opacity: 0;
        transition: 0.5s;
        pointer-events: none;
    }

    .section_inner {
        width: 100%;

        .promo__star {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            max-width: 80vw;
            z-index: 1;
            opacity: 0;
            animation: show 1s forwards 0.5s;
            transition: 0.5s;
            pointer-events: none;

            @media (max-width: $tablet) {
                max-width: none;
                width: 160vw;
            }

            @media (max-width: $mobile) {
                max-width: none;
                width: 180vw;
            }

            svg, img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            @keyframes show {
                0% {
                    opacity: 0;
                    transform: translate(-50%, -50%) scale(0);
                }

                100% {
                    opacity: 1;
                    transform: translate(-50%, -50%) scale(1);
                }
            }
        }
    }
}