h1,
.h1 {
    font-size: 48px;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -1.44px;
}

h2,
.h2 {
    font-size: 36px;
    font-weight: 700;
    line-height: 130%;
    text-transform: uppercase;

    @media (max-width: $mobile) {
        font-size: 24px;
    }
}

h3,
.h3 {
    font-size: 32px;
    font-weight: 700;
    line-height: 120%;

    @media (max-width: $tablet) {
        font-size: 24px;
    }

    @media (max-width: $mobile) {
        font-size: 22px;
    }
}

h4,
.h4 {
    font-size: 24px;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.72px;
    text-transform: uppercase;
}

h5,
.h5 {
    font-size: 24px;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.72px;
}

h6,
.h6 {
    font-size: 18px;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.54px;
}

p,
.p1 {
    font-size: 24px;
    font-weight: 300;
    line-height: 125%;
}

.p2 {
    font-size: 22px;
    font-weight: 700;
    line-height: 130%;
    text-transform: uppercase;

    @media (max-width: $tablet) {
        font-size: 16px;
    }

    @media (max-width: $mobile) {
        font-size: 12px;
    }
}

.p3 {
    font-size: 22px;
    font-weight: 400;
    line-height: 150%;

    @media (max-width: $tablet) {
        font-size: 16px;
    }

    @media (max-width: $mobile) {
        font-size: 14px;
    }
}

.p4 {
    font-size: 20px;
    font-weight: 300;
    line-height: 125%;
    letter-spacing: -0.6px;

    @media (max-width: $mobile) {
        font-size: 16px;
    }
}

.p5 {
    font-size: 16px;
    font-weight: 300;
    line-height: 125%;
    letter-spacing: -0.16px;
}

.p6 {
    font-size: 14px;
    font-weight: 300;
    line-height: 125%;
    letter-spacing: -0.14px;
}

.__site_wrapper {
    background-color: $color_bordo;
    background: radial-gradient(77.01% 35% at 50% 42.57%, #811C3C 14.79%, #620B2F 100%);

    &.__bg_1 {
        background: linear-gradient(180deg, #FF895A 0%, #E05327 89.06%);

        .footer {
            background-color: $color_orange;
        }
    }

    &.__bg_2 {
        background: radial-gradient(120.24% 40.36% at 50% 39.43%, #F47B4C 0%, #FBD1C0 100%);

        .footer {
            background-color: #FBD1C0;

            .footer__items {

                .footer__item {

                    .footer__item_title {
                        color: $color_d_bordo;
                    }

                    .footer__item_links {

                        .footer__item_link {
                            color: $color_d_bordo;

                            &::before {
                                background-color: $color_bordo;
                            }
                        }
                    }
                }
            }

            .footer__logo {

                svg {

                    path {
                        fill: $color_l_orange;
                    }
                }

                &.__right {

                    svg {

                        path {
                            fill: $color_l_orange;
                        }
                    }
                }
            }
        }
    }

    &.__bg_3 {
        background: none;
        background-color: $color_l_orange;

        .footer {
            background-color: $color_l_orange;
        }
    }
}

.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    padding: 0 64px;

    @media (max-width: $tablet) {
        padding: 0 32px;
    }

    @media (max-width: $mobile) {
        padding: 0 16px;
    }
}

.section {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
}