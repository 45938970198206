@import '../../styles/vars';

.info {
    position: relative;

    &.__hide {

        * {
            pointer-events: none !important;
        }

        &::before {
            opacity: 0;
        }

        &::after {
            opacity: 1;
        }
    }

    &::before,
    &::after {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        z-index: 10;
        pointer-events: none;
    }

    &::before {
        background: #F7A585;
        animation: hideBg 0.5s linear forwards 0.2s;
    }

    &::after {
        background-color: #F7A585;
        opacity: 0;
        transition: 0.5s;
    }

    @keyframes hideBg {
        0% {
            background: rgba(247, 165, 133, 1);
        }

        100% {
            background: rgba(224, 83, 39, 0);
        }
    }

    .section_inner {
        padding-top: 125px;
        padding-bottom: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (max-width: $tablet) {
            padding-top: 105px;
        }

        @media (max-width: $mobile) {
            padding-top: 70px;
        }

        .info__items {
            max-width: 680px;
            width: 100%;

            @media (max-width: $tablet) {
                max-width: 100%;
            }

            .info__item {
                position: relative;
                width: 100%;
                display: flex;
                flex-direction: row-reverse;
                align-items: center;
                justify-content: space-between;
                background: rgba(255, 255, 255, 0.36);
                border-radius: 16px;
                padding: 0 56px;
                cursor: pointer;
                transition: 0.5s;
                transform: translateY(50px);
                opacity: 0;
                animation: showItems 1s forwards 1s;

                @media (max-width: $tablet) {
                    padding: 0 48px;
                }

                @media (max-width: $mobile) {
                    padding: 0 24px;
                }

                &:hover {
                    background-color: $color_l_bordo;

                    .info__item_icons {

                        .info__item_icon {

                            &::before {
                                opacity: 0;
                            }

                            &::after {
                                opacity: 1;
                            }
                        }
                    }

                    .info__item_text {
                        color: $color_white;
                    }
                }

                &:nth-child(1) {

                    .info__item_icons {

                        .info__item_icon {

                            &::before {
                                background-image: url('../../assets/svg/icon_1.svg');
                            }

                            &::after {
                                background-image: url('../../assets/svg/icon_1_hov.svg');
                            }
                        }
                    }
                }

                &:nth-child(2) {

                    .info__item_icons {

                        .info__item_icon {

                            &::before {
                                background-image: url('../../assets/svg/icon_2.svg');
                            }

                            &::after {
                                background-image: url('../../assets/svg/icon_2_hov.svg');
                            }
                        }
                    }
                }

                &:nth-child(3) {

                    .info__item_icons {

                        .info__item_icon {

                            &::before {
                                background-image: url('../../assets/svg/icon_3.svg');
                            }

                            &::after {
                                background-image: url('../../assets/svg/icon_3_hov.svg');
                            }
                        }
                    }
                }

                &:nth-child(4) {

                    .info__item_icons {

                        .info__item_icon {

                            &::before {
                                background-image: url('../../assets/svg/icon_4.svg');
                            }

                            &::after {
                                background-image: url('../../assets/svg/icon_4_hov.svg');
                            }
                        }
                    }
                }

                &:not(:last-child) {
                    margin-bottom: 16px;

                    @media (max-width: $mobile) {
                        margin-bottom: 12px;
                    }
                }

                &:last-child {
                    margin-top: 56px;
                    background-color: $color_bordo;

                    @media (max-width: $tablet) {
                        margin-top: 66px;
                    }

                    @media (max-width: $mobile) {
                        margin-top: 45px;
                    }

                    &:hover {
                        background-color: $color_l_orange;
                    }

                    .info__item_text {
                        color: $color_white;
                    }
                }

                &:nth-child(odd) {
                    flex-direction: row;

                    .info__item_icons {
                        margin-left: 0;
                        margin-right: 24px;

                        @media (max-width: $mobile) {
                            margin-right: 12px;
                        }
                    }
                }

                .info__item_icons {
                    margin-left: 24px;

                    @media (max-width: $mobile) {
                        margin-left: 12px;
                    }

                    .info__item_icon {
                        position: relative;
                        min-width: 170px;
                        max-width: 170px;
                        height: 170px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        @media (max-width: $tablet) {
                            min-width: 185px;
                            max-width: 185px;
                            height: 185px;
                        }

                        @media (max-width: $mobile) {
                            min-width: 120px;
                            max-width: 120px;
                            height: 120px;
                        }

                        &::before,
                        &::after {
                            position: absolute;
                            content: '';
                            width: 100%;
                            height: 100%;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: cover;
                            transition: 0.5s;
                        }

                        &::after {
                            opacity: 0;
                        }
                    }
                }

                .info__item_text {
                    color: $color_bordo;

                    @media (max-width: $mobile) {
                        font-size: 18px;
                    }
                }

                @keyframes showItems {
                    0% {
                        transform: translateY(50px);
                        opacity: 0;
                    }

                    100% {
                        transform: translateY(0);
                        opacity: 1;
                    }
                }

            }
        }
    }
}